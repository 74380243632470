<template>
   <b-modal id="modal" size="lg"
            @close="onReset()"
            no-close-on-esc
            no-close-on-backdrop
            class="c-pages-parts-edit">
      <template #modal-title>
         <span>{{ data.name }}&nbsp;</span>
         <span v-if="data.part_name != 'content'"> - {{ data.part_name }}&nbsp;</span>
         <a :href="pageUrl(data.editable, data.url)" title="Náhled stránky" target="_blank">
            <b-icon icon="search"></b-icon>
         </a>
      </template>
      <file-manager></file-manager>
      <b-form id="form1" @reset="onReset" @submit="onSubmit" autocomplete="off">

         <template v-if="data.type == 'input-text'">
            <b-tabs v-if="languages.length > 1" content-class="mb-2">
               <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name" >
                  <b-form-input
                      class="border-top-0"
                      v-if="data.type === 'input-text'"
                      v-model="data['text_'+lang.name]"
                      type="text">
                  </b-form-input>
               </b-tab>
            </b-tabs>

            <b-form-input
                v-else
                v-model="data['text_'+languages[0].name]"
                type="text">
            </b-form-input>
         </template>

         <template v-else-if="data.type == 'text-editor'">
            <b-tabs v-if="languages.length > 1" content-class="mb-2">
               <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name" >

                  <text-editor v-model="data['text_'+lang.name]"></text-editor>
               </b-tab>
            </b-tabs>
            <text-editor
                v-else
                customClass="c-text-editor--border-top mb-2"
                v-model="data['text_'+languages[0].name]">
            </text-editor>
         </template>
    
          <template v-else-if="data.type == 'content'">
              <content-editor
                  :id-table="id"
                  :table-name="'pages_parts'"
                  :ref="'contentEditor_'+uniqId">
              </content-editor>
              
              
          </template>
          
         <template v-else >
             <image-controller
                 :id="id"
                 :img-url="data.img"
                 table-name="pages_parts"
                 table-col-name="img"
                 dir-path="upload/pages"
                 :img-width="1920"
             ></image-controller>
         </template>

      </b-form>

      <template v-slot:modal-footer>
         <div class="w-100">
            <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
              {{ $t('universalTxt.closeBtn') }}
            </b-button>
            <b-button type="submit" class="float-right" form="form1" variant="primary">
              {{ $t('universalTxt.saveBtn') }}
            </b-button>
         </div>
      </template>
   </b-modal>
</template>

<script>
import FileManager from "../../components/file-manager";
import ContentEditor from "../../components/content-editor";
import TextEditor from "../../components/text-editor";
import ImageController from "@/components/image-controller";

export default {
   name: 'pages-parts-edit',
   components: {ImageController, TextEditor, ContentEditor, FileManager},
   data() {
      return {
         data: [],
         id: parseInt(this.$route.params.id),
         uniqId: null,
         path: process.env.VUE_APP_SERVER,
      }
   },
   methods: {

      async loadData() {
         try {
            const response = await this.$http({
               url: process.env.VUE_APP_SERVER + 'api/pages/pages-part.php',
               headers: {'content-type': 'application/x-www-form-urlencoded'},
               params: {'id': this.id},
               method: 'GET'
            });

            this.data = response.data.records[0];

         } catch (error) {
            console.log("error", error);
            this.$eventHub.$emit('openMessageController', 'error', 'Žádná data');
         }

      },

      async onSubmit(e) {
         e.preventDefault();
         try {
            if (this.data.type !== 'content') {
               await this.$http({
                  url: process.env.VUE_APP_SERVER + 'api/pages/update-content.php',
                  headers: {'content-type': 'application/x-www-form-urlencoded'},
                  data: {
                     'id': this.id,
                     'data': this.data
                  },
                  method: 'PUT'
               });
            } else {
               this.$refs['contentEditor_' + this.uniqId].updateContent();
            }

            this.$bvModal.hide('modal');
            this.$router.go(-1);
            this.$eventHub.$emit('openMessageController', 'success', 'Změna proběhla úspěšně');
         } catch (error) {
            console.log("error", error);
            this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
         }

      },

      onReset() {
         this.$router.go(-1);
      },

      pageUrl(editable, url) {
         let lang = (this.languages.length > 1) ? this.languages[0].name + '/' : '';
         return this.path + lang + url;
      }

   },
   computed: {
      languages() {
         return this.$store.getters.langs
      }
   },
   mounted() {
      this.$bvModal.show('modal');
      this.uniqId = this._uid;
      this.loadData();
   },
   created() {
   }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
   transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
   opacity: 0;
}
</style>
