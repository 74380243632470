<template>
    <div class="c-image-controller">
        <div v-if="!data.img" class="c-image-controller__add">
            <div class="c-file-upload">
                <label class="c-file-upload__label" :for="'file_'+uniqId" >
                    <input type="file"
                           :id="'file_'+uniqId"
                           :ref="'file_'+uniqId"
                           accept="image/*"
                           @change="changeFileName()">
                </label>
                
                <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>
            
            </div>
            <b-icon class="c-image-controller__add-btn"
                    icon="plus-circle-fill"
                    variant="info"></b-icon>
        </div>
        <div v-else class="c-image-controller__img">
            <img :src="path + data.img" alt="">
            <b-button
                class="c-image-controller__delete-btn" size="sm" variant="danger"
                @click="removeImage()">
                <b-icon icon="trash"
                        variant="white"
                ></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>

export default {
    name: "image-controller",
    props: {
        id:             	Number,
        imgUrl:             String,
        tableName:      	String,
        dirPath:        	String,
        tableColName:   	{ type: String, default: 'img' },
        tableColNameWebp:	{ type: String, default: 'img_webp' },
        imgWidth:       	{ type: Number, default: 1200 },
        imgQuality:     	{ type: Number, default: 75 },
        imgFormat:      	{ type: String, default: 'jpg' }
    },
    data() {
        return {
            file: [],
            data: { img : null },
            uploadPercentage: 0,
            uploading: false,
            uniqId: null,
            path: process.env.VUE_APP_SERVER
        }
    },
    methods: {
        changeFileName() {
            
            this.file = this.$refs['file_'+this.uniqId].files;
            this.uploadFile();
        },
        async uploadFile() {
            try {
                this.uploading = true;
                
                let formData = new FormData();
                
                for( var i = 0; i < this.$refs['file_'+this.uniqId].files.length; i++ ){
                    let file = this.$refs['file_'+this.uniqId].files[i];
                    console.log(file);
                    formData.append('file[' + i + ']', file);
                }
                
                formData.append('id', this.id);
                formData.append('table_name', this.tableName);
                formData.append('dir_path', this.dirPath);
                formData.append('table_col_name', this.tableColName);
                formData.append('table_col_name_webp', this.tableColNameWebp);
                formData.append('img_width', this.imgWidth);
                formData.append('img_quality', this.imgQuality);
                formData.append('img_format', this.imgFormat);
                
                const response =  await this.$http.post( process.env.VUE_APP_SERVER + 'api/image-controller/add-images.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function( progressEvent ) {
                            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                        }.bind(this)
                    }
                );
                
                this.data = response.data;
                this.file = [];
                this.uploading = false;
                this.$eventHub.$emit('openMessageController', 'success', '✓');
                
            } catch (error) {
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                this.file = [];
                this.uploading = false;
            }
        },
        async removeImage() {
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/image-controller/remove-item.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {
                            'id': this.id,
                            'table_name': this.tableName,
                            'table_col_name': this.tableColName,
                            'table_col_name_webp': this.tableColNameWebp
                        },
                        method: 'DELETE'
                    });
                    this.data = [];
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/image-controller/img-src.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {
                        'id': this.id,
                        'table_name': this.tableName,
                        'table_col_name': this.tableColName
                    },
                    method: 'GET'
                });
                
                this.data = response.data;
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
    },
    mounted() {
        this.uniqId = this._uid;
        if (this.imgUrl) {
            this.data = { img : this.imgUrl }
        } else {
            this.loadData();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
