<template>
    <div class="c-file-manager c-file-manager--local">
        <div class="">
            <div class="c-file-manager__upload-btn" title="Přidat soubor">
                <div class="c-file-upload">
                    <label class="c-file-upload__label" :for="'file_'+uniqId" >
                        <input type="file"
                               :id="'file_'+uniqId"
                               :ref="'file_'+uniqId"
                               @change="changeFileName()"
                               multiple="multiple">
                    </label>

                    <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>
                </div>
                <b-icon class="c-image-upload__add"
                        icon="plus-circle-fill"
                        variant="info"
                ></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "content-file-upload",
        props: {
            id: Number,
            tableName: String
        },
        data(){
            return {
                file: [],
                uploadPercentage: 0,
                uploading: false,
                uniqId: null
            }
        },
        methods: {
            changeFileName() {

                this.file = this.$refs['file_'+this.uniqId].files;
                this.uploadFile();
            },


            async uploadFile() {
                try {
                    this.uploading = true;

                    let formData = new FormData();

                    for( var i = 0; i < this.$refs['file_'+this.uniqId].files.length; i++ ){
                        let file = this.$refs['file_'+this.uniqId].files[i];
                        formData.append('file[' + i + ']', file);
                    }

                    formData.append('id', this.id);
                    formData.append('table-name', this.tableName);

                    await this.$http.post( process.env.VUE_APP_SERVER + 'api/content/add-files.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        }
                    );

                    this.$emit('refreshData');
                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                } finally {
                    this.file = [];
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.$refs['file_'+this.uniqId].value = null;
                }
            },
        },
        mounted() {
            this.uniqId = this._uid
        },
    }
</script>

<style lang="scss" scoped>

</style>
