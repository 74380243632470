<template>
    <div class="c-load-spinner">
        <div class="c-load-spinner__wrap">
            <div></div><div></div><div></div><div></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "load-spinner"
    }
</script>

<style scoped>

</style>
