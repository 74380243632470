<template>
    <div>
        <transition name="fade">
            <div v-if="loaded">
                
                <div v-for="(item, index) in data" :key="item.id">
                    
                    
                    <div class="c-pages-parts-edit__wrap" v-bind:class="{ 'c-image-upload' : item.type === 'images'}">
                        
                        <div class="c-pages-parts-edit__control-wrap">
                            
                            <b-input-group class="c-pages-parts-edit__anchor" prepend="#" size="sm"
                                           title="Název kotvy piště v celku bez diakritiky a oddělujte pomlčkou např. 'sekce-1-hlavni'">
                                
                                <b-form-input v-model="item.anchor"
                                              pattern="[a-zA-Z\-0-9]+"
                                              placeholder="Kotva">
                                
                                </b-form-input>
                            </b-input-group>
                            
                            
                            <b-button v-if="item.type === 'text'" v-b-toggle.file-manager class="ml-1" size="sm"
                                      title="souborový manažer" variant="info">
                                <b-icon icon="file-earmark-plus"></b-icon>
                            </b-button>
                            
                            <b-button v-if="index !== 0" class="ml-1" size="sm" variant="info"
                                      @click="sortParts('up', item.sort, idTable, tableName)">
                                <b-icon icon="arrow-up"
                                        variant="white"
                                ></b-icon>
                            </b-button>
                            <b-button v-if="data.length-1 !== index" class="ml-1" size="sm" variant="info"
                                      @click="sortParts('down', item.sort, idTable, tableName)">
                                <b-icon icon="arrow-down"
                                        variant="white"
                                ></b-icon>
                            </b-button>
                            <b-button class="ml-3" size="sm" variant="danger"
                                      @click="removeItem(item.id)">
                                <b-icon icon="trash"
                                        variant="white"
                                ></b-icon>
                            </b-button>
                        
                        </div>
                        
                        <!-- TEXT ------------------------------------------------------------------------  -->
                        
                        <template v-if="item.type === 'text'">
                            <h2 class="c-pages-parts-edit__title">Text</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="textEditorItem in item.items">
                                            <text-editor v-model="textEditorItem.text"
                                                         v-if="lang.id === textEditorItem.id_lang"
                                                         :key="textEditorItem.id"
                                            >
                                            </text-editor>
                                        </template>
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </template>
                        
                        <!-- TEXT IMAGE ------------------------------------------------------------------------  -->
                        
                        <template v-if="item.type === 'text_image'">
                            <h2 class="c-pages-parts-edit__title">Text s obrázkem</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                <div :class="{ 'flex-row-reverse' : item.items[0].direction == 1 }" class="row mt-4">
                                    <div class="col-12 col-lg-3">
                                        <image-controller
                                            :id="item.items[0].id"
                                            dir-path="upload/content/text_image"
                                            table-col-name="image"
                                            table-col-name-webp="image_webp"
                                            table-name="content_parts_items_text_image"
                                        ></image-controller>
                                    </div>
                                    <div class="col-12 col-lg-1 d-flex justify-content-center align-items-center">
                                        <b-button class="my-2" size="sm" title="Prohodit pořadí" variant="primary"
                                                  @click="item.items[0].direction = !item.items[0].direction">
                                            <b-icon icon="arrow-left-right"
                                                    variant="white"
                                            ></b-icon>
                                        </b-button>
                                    
                                    </div>
                                    <div class="col-12 col-lg-8">
                                        <b-tabs content-class="mb-2">
                                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                                <text-editor v-model="item.items[0]['text_'+lang.name]" :hide-h2="true">
                                                </text-editor>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </div>
                            </b-card>
                        </template>
                        
                        <!-- IMAGES ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'images'">
                            <h2 class="c-pages-parts-edit__title">Fotky</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                <div class="c-image-upload__wrap">
                                    <div v-for="(img, indexImg) in item.items"
                                         :key="'img_'+img.id"
                                         :ref="'img_'+uniqId+'_'+index+'_'+indexImg"
                                         class="c-image-upload__img"
                                         @click="toggleClass('c-image-upload__img--editing', 'img_'+uniqId+'_'+index+'_'+indexImg)">
                                        <img :src="path + img.url_thumb"/>
                                        <div class="c-image-upload__controls">
                                            
                                            <b-tabs
                                                :class="languages.length == 1 ? 'c-image-upload__title-tab--single' : '' "
                                                class="c-image-upload__title-tab">
                                                <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                                    <b-form-textarea
                                                        v-model="img['name_'+lang.name]"
                                                        :key="img.id"
                                                        :class="languages.length == 1 ? 'content-editor__lang-input--single' : '' "
                                                        class="content-editor__lang-input"
                                                        max-rows="3"
                                                        placeholder="Popis obrázku"
                                                        row="1">
                                                    </b-form-textarea>
                                                </b-tab>
                                            </b-tabs>
                                            
                                            <b-button v-if="indexImg !== 0" class="ml-1" size="sm" variant="info"
                                                      @click="sortItem('up', img.sort, item.id, 'content_parts_items_images')">
                                                <b-icon icon="arrow-up-left" variant="white"></b-icon>
                                            </b-button>
                                            <b-button v-if="item.items.length-1 !== indexImg" class="ml-1" size="sm"
                                                      variant="info"
                                                      @click="sortItem('down', img.sort, item.id, 'content_parts_items_images')">
                                                <b-icon icon="arrow-down-right" variant="white"></b-icon>
                                            </b-button>
                                            <b-button class="ml-2 mr-1" size="sm" variant="danger"
                                                      @click="removeImage(img.id)">
                                                <b-icon icon="trash" variant="white"></b-icon>
                                            </b-button>
                                        </div>
                                    
                                    
                                    </div>
                                    
                                    <div class="c-image-upload__img c-image-upload__img--new">
                                        <b-icon class="c-image-upload__add"
                                                icon="plus-circle-fill"
                                                variant="info"></b-icon>
                                        
                                        <content-img-upload :id="item.id"
                                                            @refreshData="updateAndReload()"></content-img-upload>
                                    </div>
                                
                                </div>
                            
                            </b-card>
                        
                        </template>
                        
                        <!-- ICONS ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'icons'">
                            <h2 class="c-pages-parts-edit__title">Ikony</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                
                                <div class="c-icons-content">
                                    <div v-for="(icon, indexIcons) in item.items"
                                         :key="'icon_'+icon.id"
                                         :ref="'icon_'+uniqId+'_'+index+'_'+indexIcons"
                                         class="c-icons-content__item">
                                        <img
                                            :src="icon.url_thumb !== '' ? (path + icon.url_thumb) : (path + icon.url) "/>
                                        <div class="c-icons-content__form-content">
                                            
                                            <div class="form-group c-btns-content__form-title">
                                                <input
                                                    v-for="lang in languages" v-model="icon['link_url_'+lang.name]"
                                                    :key="'url_'+lang.id"
                                                    :placeholder="'Url odkaz '+lang.name"
                                                    class="form-control form-control-sm border-bottom-0"
                                                    type="link"
                                                >
                                            </div>
                                            
                                            <div class="form-group c-icons-content__form-title">
                                                <input
                                                    v-for="lang in languages" v-model="icon['name_'+lang.name]"
                                                    :key="lang.id"
                                                    :placeholder="'Název '+lang.name"
                                                    :title="lang.name"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        
                                        <div class="c-icons-content__controls c-icons-content__controls">
                                            <div>
                                                <b-button v-show="indexIcons !== 0"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('up', icon.sort, item.id, 'content_parts_items_icons')">
                                                    <b-icon icon="arrow-up" variant="white"></b-icon>
                                                </b-button>
                                                <b-button v-show="item.items.length-1 !== indexIcons"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('down', icon.sort, item.id, 'content_parts_items_icons')">
                                                    <b-icon icon="arrow-down" variant="white"></b-icon>
                                                </b-button>
                                            </div>
                                            <b-button
                                                class="c-file-controller__delete-btn" size="sm" variant="danger"
                                                @click="removeIcon(icon.id)">
                                                <b-icon icon="trash"
                                                        variant="white"
                                                ></b-icon>
                                            </b-button>
                                        </div>
                                    
                                    </div>
                                    
                                    <content-icons-upload :id="item.id" :table-name="tableName"
                                                          @refreshData="updateAndReload()"></content-icons-upload>
                                
                                </div>
                            
                            </b-card>
                        
                        </template>
                        
                        <!-- BTNS ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'btns'">
                            <h2 class="c-pages-parts-edit__title">Tlačítka</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                <div class="c-btns-content">
                                    <div v-for="(btn, indexBtns) in item.items"
                                         :key="'btns_'+btn.id"
                                         :ref="'btns_'+uniqId+'_'+index+'_'+indexBtns"
                                         class="c-btns-content__item">
                                        
                                        <div class="c-btns-content__radio-btns">
                                            <b-form-radio v-model="btn.theme" value="primary">
                                                Primární
                                            </b-form-radio>
                                            <b-form-radio v-model="btn.theme" value="secondary">
                                                Sekundární
                                            </b-form-radio>
                                        </div>
                                        
                                        <div class="c-btns-content__form-content">
                                            
                                            <div class="form-group c-btns-content__form-title">
                                                <input
                                                    v-for="lang in languages" v-model="btn['url_'+lang.name]"
                                                    :key="'url_'+lang.id"
                                                    :placeholder="'Url odkaz '+lang.name"
                                                    class="form-control form-control-sm border-bottom-0"
                                                    type="link"
                                                >
                                            </div>
                                            
                                            <div class="form-group c-btns-content__form-title">
                                                <input
                                                    v-for="lang in languages" v-model="btn['name_'+lang.name]"
                                                    :key="lang.id"
                                                    :placeholder="'Název '+lang.name"
                                                    :title="lang.name"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        
                                        <div class="c-btns-content__controls c-btns-content__controls">
                                            <div>
                                                <b-button v-show="indexBtns !== 0"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('up', btn.sort, item.id, 'content_parts_items_btns')">
                                                    <b-icon icon="arrow-up" variant="white"></b-icon>
                                                </b-button>
                                                <b-button v-show="item.items.length-1 !== indexBtns"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('down', btn.sort, item.id, 'content_parts_items_btns')">
                                                    <b-icon icon="arrow-down" variant="white"></b-icon>
                                                </b-button>
                                            </div>
                                            <b-button
                                                class="c-file-controller__delete-btn" size="sm" variant="danger"
                                                @click="removeBtn(btn.id)">
                                                <b-icon icon="trash"
                                                        variant="white"
                                                ></b-icon>
                                            </b-button>
                                        </div>
                                    
                                    </div>
                                    <a class="c-btns-content__add" @click="addBtn(item.id)">
                                        <b-icon class="c-btns-content__add-ico" icon="plus-circle-fill"
                                                variant="info"
                                        ></b-icon>
                                    </a>
                                
                                </div>
                            
                            </b-card>
                        
                        </template>
                        
                        <!-- FILES ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'files'">
                            <h2 class="c-pages-parts-edit__title">Soubory</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                <content-file-upload :id="item.id" :table-name="tableName"
                                                     @refreshData="updateAndReload()"></content-file-upload>
                                <!-- file ---------------------------------- -->
                                
                                
                                <div class="c-file-controller mt-2">
                                    
                                    <div v-for="(file, indexFile) in item.items" :key="'file_'+file.id"
                                         class="c-file-controller__file">
                                        
                                        <div class="c-file-controller__icon">
                                            <b-icon v-if="file.type === 'audio/mpeg'"
                                                    icon="file-earmark-music"
                                                    variant="info"></b-icon>
                                            
                                            <b-icon
                                                v-else-if="file.type === 'video/mp4' || file.type === 'video/quicktime'"
                                                icon="file-earmark-play"
                                                variant="info"></b-icon>
                                            
                                            <b-icon
                                                v-else-if="file.type === 'application/zip' || file.type === 'application/x-rar-compressed' || file.type === 'application/x-rar'"
                                                icon="file-earmark-zip"
                                                variant="info"></b-icon>
                                            
                                            <b-icon v-else-if="file.type === 'application/pdf'"
                                                    icon="file-earmark-richtext"
                                                    variant="info"></b-icon>
                                            
                                            <img v-else-if="file.type === 'image/jpeg' ||
                                                                file.type === 'image/png' ||
                                                                file.type === 'image/gif' "
                                                 :src="path + file.url"/>
                                            
                                            <b-icon v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                    file.type === 'application/vnd.ms-excel'"
                                                    icon="file-earmark-spreadsheet"
                                                    variant="info"></b-icon>
                                            
                                            <b-icon v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                                                                    file.type === 'application/vnd.ms-powerpoint'"
                                                    icon="file-earmark-easel"
                                                    variant="info"></b-icon>
                                            
                                            <b-icon v-else
                                                    icon="file-earmark-text"
                                                    variant="info"></b-icon>
                                        </div>
                                        
                                        
                                        <b-input-group v-if="editAble === file.id"
                                                       class="c-file-controller__edit-name-input"
                                                       size="sm">
                                            <b-form-input
                                                v-model="newFileName"
                                                placeholder="Nový název"
                                                type="text"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-button variant="info" @click="changeName()">{{ $t('universalTxt.saveBtn') }}</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        
                                        <a v-else :href="path + file.url" :title="path + file.url" download
                                           target="_blank">
                                            {{ file.name }}
                                        </a>
                                        
                                        <div class="c-file-controller__controls">
                                            
                                            <b-button v-if="indexFile !== 0"
                                                      class="c-file-controller__btn-on-hover"
                                                      size="sm"
                                                      variant="info"
                                                      @click="sortItem('up', file.sort, item.id, 'content_parts_items_files')">
                                                <b-icon icon="arrow-up" variant="white"></b-icon>
                                            </b-button>
                                            <b-button v-if="item.items.length-1 !== indexFile"
                                                      class="c-file-controller__btn-on-hover"
                                                      size="sm"
                                                      variant="info"
                                                      @click="sortItem('down', file.sort, item.id, 'content_parts_items_files')">
                                                <b-icon icon="arrow-down" variant="white"></b-icon>
                                            </b-button>
                                            
                                            
                                            <b-button size="sm"
                                                      title="Změnit název"
                                                      variant="info"
                                                      @click="setEditable(file.id, file.name)">
                                                <b-icon icon="pencil-square" variant="white"></b-icon>
                                            </b-button>
                                            
                                            <b-button
                                                class="c-file-controller__delete-btn" size="sm" variant="danger"
                                                @click="removeFile(file.id)">
                                                <b-icon icon="trash"
                                                        variant="white"
                                                ></b-icon>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            
                            
                            </b-card>
                        
                        </template>
                        
                        <!-- VIDEO ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'video'">
                            <h2 class="c-pages-parts-edit__title">Video</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                
                                <b-input-group class="mt-3">
                                    <b-input-group-append>
                                        <b-form-radio-group
                                            v-model="item.items.type"
                                            :options="videoOptions"
                                            button-variant="outline-primary"
                                            buttons
                                            class="content-editor__color-theme"
                                            title="Barva pozadí"
                                        ></b-form-radio-group>
                                    </b-input-group-append>
                                    <input v-model="item.items.url"
                                           class="form-control"
                                           placeholder="Url video">
                                </b-input-group>
                            
                            </b-card>
                        </template>
                        
                        <!-- PODCAST ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'podcast'">
                            <h2 class="c-pages-parts-edit__title">Podcast</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                
                                <div class="form-group">
                           <textarea v-model="item.items.iframe"
                                     v-b-tooltip.hover
                                     class="form-control"
                                     placeholder="Vložte <iframe> například ze Spotify"
                                     rows="3"
                                     title="Ve Spotify -> Sdílet -> Kopírovat kód pro vložení">
                           </textarea>
                                </div>
                            
                            
                            </b-card>
                        </template>
                        
                        <!-- MAP ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'map'">
                            <h2 class="c-pages-parts-edit__title">Mapa</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                
                                <code class="form-group">
                                    <textarea v-model="item.items.map_iframe"
                                              class="form-control"
                                              placeholder="Vlož iframe mapy"></textarea>
                                </code>
                            
                            
                            </b-card>
                        </template>
                        
                        <!-- TESTIMONIALS ------------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'testimonial'">
                            <h2 class="c-pages-parts-edit__title">Testimoniál</h2>
                            <b-card border-variant="info" class="mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <b-tabs content-class="mb-2">
                                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                                <template v-for="title in item.titles">
                                                    <input v-model="title.title"
                                                           v-if="lang.id === title.id_lang"
                                                           :key="title.id"
                                                           class="form-control content-editor__lang-input"
                                                           placeholder="Titulek…">
                                                </template>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                    <div class="col-12 col-lg-9">
                                        <label>Citace</label>
                                        <b-tabs content-class="mb-2">
                                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                                <text-editor v-model="item.items[0]['text_'+lang.name]"
                                                             :key="'testim_'+lang.id" :hide-h2="true">
                                                </text-editor>
                                            </b-tab>
                                        </b-tabs>
                                        <label>Jméno</label>
                                        <b-tabs content-class="mb-2">
                                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                                <text-editor v-model="item.items[0]['title_'+lang.name]"
                                                             :key="'testimname_'+lang.id" :hide-h2="true">
                                                </text-editor>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <image-controller
                                            :id="item.items[0].id"
                                            :imgWidth="600"
                                            dir-path="upload/content/testimonial"
                                            table-col-name="image"
                                            table-col-name-webp="image_webp"
                                            table-name="content_parts_items_testimonial"
                                        ></image-controller>
                                        
                                        <div class="row">
                                            <div class="col-12">
                                                <b-form-checkbox v-model="item.items[0].direction"
                                                                 class="d-flex justify-content-center mt-1"
                                                                 name="check-button"
                                                                 switch
                                                                 value="1">
                                                    <b>Velikost</b>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    
                                    
                                    </div>
                                
                                </div>
                            </b-card>
                        </template>
                        
                        <!-- IFRAME ---------------------------------------------------------------------  -->
                        
                        <template v-else-if="item.type === 'iframe'">
                            <h2 class="c-pages-parts-edit__title">Iframe</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                
                                <div class="form-group">
                                    <b-tabs content-class="mb-2">
                                        <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                            <textarea v-model="item.items['iframe_'+lang.name]"
                                                      v-b-tooltip.hover
                                                      class="form-control content-editor__lang-input"
                                                      placeholder="Vložte <iframe> nebo <script>"
                                                      rows="3">
                                            </textarea>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            
                            
                            </b-card>
                        </template>
    
                        <!-- DROPDOWNS ---------------------------------------------------------------------  -->
    
                        <template v-else-if="item.type === 'dropdowns'">
                            <h2 class="c-pages-parts-edit__title">Dropdown</h2>
                            <b-card border-variant="info" class="mb-2">
                                <b-tabs content-class="mb-2">
                                    <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
                                        <template v-for="title in item.titles">
                                            <input v-model="title.title"
                                                   v-if="lang.id === title.id_lang"
                                                   :key="title.id"
                                                   class="form-control content-editor__lang-input"
                                                   placeholder="Titulek…"
                                                   title="Titulek…">
                                        </template>
                                    </b-tab>
                                </b-tabs>
                                <div class="c-dropdowns-content">
                                    <div v-for="(dropdown, indexDropdowns) in item.items"
                                         :key="'btns_'+dropdown.id"
                                         :ref="'btns_'+uniqId+'_'+index+'_'+indexDropdowns"
                                         class="c-dropdowns-content__item">
    
                                        <b-tabs content-class="mb-2">
                                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name">
    
    
                                                <input
                                                    :key="lang.id"
                                                    :placeholder="'Nadpis dropdownu '+lang.name"
                                                    :title="lang.name"
                                                    v-model="dropdown['name_'+lang.name]"
                                                    class="form-control c-dropdowns-content__name-input">
    
    
                                                <text-editor v-model="dropdown['content_'+lang.name]"
                                                             :key="'dropdown_'+lang.id" :hide-h2="true">
                                                </text-editor>

                                              
                                            </b-tab>
                                        </b-tabs>
    
                                        <div class="c-dropdowns-content__controls c-dropdowns-content__controls">
                                            <div>
                                                <b-button v-show="indexDropdowns !== 0"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('up', dropdown.sort, item.id, 'content_parts_items_dropdowns')">
                                                    <b-icon icon="arrow-up" variant="white"></b-icon>
                                                </b-button>
                                                <b-button v-show="item.items.length-1 !== indexDropdowns"
                                                          class="c-file-controller__btn-on-hover float-right"
                                                          size="sm"
                                                          variant="info"
                                                          @click="sortItem('down', dropdown.sort, item.id, 'content_parts_items_dropdowns')">
                                                    <b-icon icon="arrow-down" variant="white"></b-icon>
                                                </b-button>
                                            </div>
                                            <b-button
                                                class="c-file-controller__delete-btn" size="sm" variant="danger"
                                                @click="removeDropdown(dropdown.id)">
                                                <b-icon icon="trash"
                                                        variant="white"
                                                ></b-icon>
                                            </b-button>
                                        </div>
                
                                    </div>
                                    <a class="c-dropdowns-content__add" @click="addDropdown(item.id)">
                                        <b-icon class="c-dropdowns-content__add-ico" icon="plus-circle-fill"
                                                variant="info"
                                        ></b-icon>
                                    </a>
            
                                </div>
        
                            </b-card>
    
                        </template>
                    </div>
                </div>
            </div>
        </transition>
        <load-spinner v-if="!loaded"></load-spinner>
        
        <!-- CONTROLS --------------------------------------------------------------- -->
        
        
        <b-row class="justify-content-md-center align-items-center  c-pages-parts-edit__add-row">
            
            <!--<b-icon class="c-pages-parts-edit__ico" icon="plus-circle-fill" variant="info" font-scale="1.7"></b-icon>-->
            
            <div class="col mx-auto text-center">
                <b-button class="c-pages-parts-edit__add"
                          size="sm" variant="info"
                          @click="addItem('text')">
                    <span>Text</span>
                    <b-icon icon="card-text" variant=""></b-icon>
                </b-button>

                <b-button class="c-pages-parts-edit__add"
                          size="sm" variant="info"
                          @click="addItem('images')">
                    <span>Obrázky</span>
                    <b-icon icon="image" variant=""></b-icon>
                </b-button>
                <b-button class="c-pages-parts-edit__add"
                          size="sm" variant="info"
                          @click="addItem('files')">
                    <span>Soubory</span>
                    <b-icon icon="file-earmark-plus" variant=""></b-icon>
                </b-button>
                <b-button class="c-pages-parts-edit__add"
                          size="sm" variant="info"
                          @click="addItem('map')">
                    <span>Mapa</span>
                    <b-icon icon="map" variant=""></b-icon>
                </b-button>
                <b-button class="c-pages-parts-edit__add"
                          size="sm" variant="info"
                          @click="addItem('btns')">
                    <span>Tlačítka</span>
                    <b-icon icon="link" variant=""></b-icon>
                </b-button>

            </div>
        </b-row>
    </div>
</template>

<script>
import TextEditor from "./text-editor";
import ContentImgUpload from "./content-img-upload";
import LoadSpinner from "./load-spinner";
import ContentFileUpload from "./content-file-upload";
import ContentIconsUpload from "@/components/content-icons-upload";
import ImageController from "@/components/image-controller";

export default {
    name: "content-editor",
    components: {ImageController, ContentIconsUpload, ContentFileUpload, LoadSpinner, ContentImgUpload, TextEditor},
    props: {
        idTable: Number, // např. ID v tabulce articles
        tableName: String // např. název tabulky 'articles'
    },
    data() {
        return {
            data: [],
            path: process.env.VUE_APP_SERVER,
            loaded: false,
            editAble: null,
            newFileName: null,
            uniqId: null,
            themeOptions: [
                {text: 'Barva 1', value: 0},
            ],
            videoOptions: [
                {text: 'Youtube', value: 'youtube'},
                {text: 'Vimeo', value: 'vimeo'}
            ]
        }
    },
    methods: {
        
        setEditable(id, name) {
            this.newFileName = name;
            this.editAble = id;
        },
        
        async changeName() {
            await this.updateContent();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/update-file-name.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        'id': this.editAble,
                        'name': this.newFileName,
                    },
                    method: 'PUT'
                });
                
                this.editAble = null;
                this.newFileName = null;
                await this.loadData();
                this.$eventHub.$emit('openMessageController', 'success', 'Změna proběhla úspěšně');
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/list-parts-items.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {
                        'id_table': this.idTable,
                        'table_name': this.tableName
                    },
                    method: 'GET'
                });
                
                this.data = response.data.records;
                this.loaded = true;
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        
        async updateContent() {
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/update-parts-items.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: this.data,
                    method: 'PUT'
                });
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
            
        },
        
        async addItem(type) {
            await this.updateContent();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/add-parts-items.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        id_table: this.idTable,
                        table_name: this.tableName,
                        type: type
                    },
                    method: 'POST'
                });
                this.$eventHub.$emit('openMessageController', 'success', 'Položka byla přidána');
                await this.loadData();
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async addBtn(contentId) {
            await this.updateContent();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/add-btns.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        id_content_parts_items: contentId,
                    },
                    method: 'POST'
                });
                
                this.$eventHub.$emit('openMessageController', 'success', 'Položka byla přidána');
                await this.loadData();
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
    
        async addDropdown(contentId) {
            await this.updateContent();
            try {
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/add-dropdowns.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        id_content_parts_items: contentId,
                    },
                    method: 'POST'
                });
            
                this.$eventHub.$emit('openMessageController', 'success', 'Položka byla přidána');
                await this.loadData();
            
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async removeImage(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items-image.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {id: id},
                        method: 'DELETE'
                    });
                    
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async removeIcon(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items-icon.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {id: id},
                        method: 'DELETE'
                    });
                    
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                    
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async removeBtn(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items-btn.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {id: id},
                        method: 'DELETE'
                    });
                    
                    
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
    
        async removeDropdown(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items-dropdown.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {id: id},
                        method: 'DELETE'
                    });
                
                
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async removeItem(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {id: id},
                        method: 'DELETE'
                    });
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', 'Položka byla smazána');
                }
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async removeFile(id) {
            await this.updateContent();
            try {
                if (confirm(this.$t('projects.deleteProject'))) {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/content/remove-parts-items-file.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {
                            'id': id
                        },
                        method: 'DELETE'
                    });
                    this.$eventHub.$emit('openMessageController', 'success', 'Soubor byl smazán');
                }
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            } finally {
                await this.loadData();
            }
            
        },
        
        async sortParts(direction, sort, idTable, tableName) {
            await this.updateContent();
            try {
                
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/sort-parts.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        sort: sort,
                        id_table: idTable,
                        table_name: tableName,
                        direction: direction
                    },
                    method: 'POST'
                });
                await this.loadData();
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        
        async sortItem(direction, sort, idContentPartsItems, tableName) {
            await this.updateContent();
            try {
                
                await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/content/sort-parts-items.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {
                        'sort': sort,
                        'id_content_parts_items': idContentPartsItems,
                        'direction': direction,
                        'table_name': tableName
                    },
                    method: 'POST'
                });
                
                await this.loadData();
                
            } catch (error) {
                this.$eventHub.$emit('openMessageController', 'error', 'Operace se nezdařila');
            }
        },
        
        async updateAndReload() {
            try {
                await this.updateContent();
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', 'Chyba');
            } finally {
                await this.loadData();
            }
        },
        toggleClass(className, ref) {
            this.$refs[ref][0].classList.toggle(className);
        }
        
    },
    computed: {
        languages() {
            return this.$store.getters.langs
        },
        
        isColorTheme: function () {
            return this.$store.getters.getColorTheme;
        }
        
    },
    mounted() {
        this.uniqId = this._uid;
    },
    created() {
        this.loadData();
    }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
